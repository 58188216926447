import { callApi } from "../../services/apiService";

const tasks = {
  namespaced: true,
  state: {
    task: {},
    tasks: [],
    loading: false,
  },

  mutations: {
    SET_TASK(state, data) {
      state.task = data;
    },
    SET_TASKS(state, data) {
      state.tasks = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    async getTasks({ rootState, commit }) {
      commit("SET_LOADING", true);
      try {
        const res = await callApi("get", `${rootState.apiURL}/getTasks`);
        let tasksData = res.data;
        if (tasksData) {
          commit("SET_TASKS", tasksData);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
  getters: {
    getTask(state) {
      return state.task;
    },
    getTasks(state) {
      return state.tasks;
    },
    getLoading(state) {
      return state.loading;
    },
  },
};

export default tasks;
