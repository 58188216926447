import axios from "axios";
import msalInstance from "./authService";

/**
 * @param {string} method - HTTP method ('get', 'post', 'put', 'delete')
 * @param {string} url - API endpoint URL
 * @param {object} [data] - Request payload (if applicable)
 * @returns {Promise} - axios response promise
 */

export async function callApi(method, url, data = null) {
  const request = {
    scopes: ["api://1cb26cf7-5afc-462c-b5a2-d9a90fb232da/.default"],
  };

  let accessToken;
  try {
    const response = await msalInstance.acquireTokenSilent(request);
    accessToken = response.accessToken;
  } catch (error) {
    // const response = await msalInstance.acquireTokenPopup(request);
    // accessToken = response.accessToken;
    console.error("Error acquiring token silently:", error);
    throw new Error("Failed to acquire access token");
  }

  const headers = { authorization: `Bearer ${accessToken}` };

  switch (method.toLowerCase()) {
    case "get":
      return axios.get(url, { headers });
    case "post":
      return axios.post(url, data, { headers });
    case "put":
      return axios.put(url, data, { headers });
    case "delete":
      return axios.delete(url, { headers });
    default:
      throw new Error(`Unsupported HTTP method: ${method}`);
  }
}
