import { callApi } from "../../services/apiService";

const surgeons = {
  namespaced: true,
  state: {
    surgeon: {},
    surgeons: [],
    loading: false,
  },

  mutations: {
    SET_SURGEON(state, data) {
      state.surgeon = data;
    },
    SET_SURGEONS(state, data) {
      state.surgeons = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },

  actions: {
    async setSurgeon({ rootState, commit, dispatch }, payload) {
      try {
        const res = await callApi(
          "post",
          `${rootState.apiURL}/createSurgeon`,
          payload
        );
        const surgeon = res.data;
        if (surgeon) {
          commit("SET_SURGEON", surgeon);
          dispatch("setSurgeons");
        }
      } catch (error) {
        console.error("Error creating surgeon:", error);
      }
    },

    async setSurgeons({ rootState, commit }) {
      commit("SET_LOADING", true);
      try {
        const res = await callApi("get", `${rootState.apiURL}/getSurgeons`);
        const surgeonsData = res.data;
        if (surgeonsData) {
          commit("SET_SURGEONS", surgeonsData);
        }
      } catch (error) {
        console.error("Error fetching surgeons:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async querySurgeons({ rootState, commit }, payload) {
      commit("SET_LOADING", true);
      try {
        const res = await callApi(
          "post",
          `${rootState.apiURL}/querySurgeons`,
          payload
        );
        const surgeonsData = res.data.items;
        if (surgeonsData) {
          commit("SET_SURGEONS", surgeonsData);
        }
      } catch (error) {
        console.error("Error querying surgeons:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async deleteSurgeon({ rootState, dispatch }, payload) {
      try {
        await callApi("post", `${rootState.apiURL}/deleteSurgeon`, payload);
        dispatch("setSurgeons");
        console.log("Surgeon deleted successfully.");
      } catch (error) {
        console.error("Error deleting surgeon:", error);
      }
    },
  },

  getters: {
    getSurgeon(state) {
      return state.surgeon;
    },
    getSurgeons(state) {
      return state.surgeons;
    },
    getLoading(state) {
      return state.loading;
    },
  },
};

export default surgeons;
