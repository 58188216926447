import { callApi } from "../../services/apiService";

const cases = {
  namespaced: true,
  state: {
    caseItem: {},
    cases: [],
    loading: true,
  },
  getters: {
    getCase(state) {
      return state.caseItem;
    },
    getCases(state) {
      return state.cases;
    },
    getLoading(state) {
      return state.loading;
    },
  },
  mutations: {
    SET_CASE(state, data) {
      state.caseItem = data;
    },
    SET_CASES(state, data) {
      state.cases = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    async getCase({ rootState, commit }, payload) {
      commit("SET_LOADING", true);
      try {
        const res = await callApi(
          "get",
          `${rootState.apiURL}/getCase?id=${payload}`
        );
        if (res.data) {
          commit("SET_CASE", res.data);
        }
      } catch (error) {
        console.error("Error fetching case:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async getCases({ rootState, commit }) {
      commit("SET_LOADING", true);
      try {
        const res = await callApi("get", `${rootState.apiURL}/getCases`);
        if (res.data) {
          commit("SET_CASES", res.data);
        }
      } catch (error) {
        console.error("Error fetching cases:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async deleteCase({ rootState, dispatch }, payload) {
      try {
        await callApi("post", `${rootState.apiURL}/deleteCase`, payload);
        dispatch("getCases");
        console.log("Delete case successful.");
      } catch (error) {
        console.error("Error deleting case:", error);
      }
    },
    async updateCase({ rootState, commit }, payload) {
      commit("SET_LOADING", true);
      try {
        const res = await callApi(
          "post",
          `${rootState.apiURL}/updateCase`,
          payload
        );
        console.log("Update case response:", res.data);
      } catch (error) {
        console.error("Error updating case:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
};

export default cases;
