import msalInstance from "../../services/authService";

const accounts = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    account: {},
    accessToken: "",
    currentAccount: {},
  },

  mutations: {
    SET_IS_AUTHENTICATED(state, data) {
      state.isAuthenticated = data;
    },
    SET_ACCOUNT(state, data) {
      state.account = data;
    },
    SET_CURRENT_ACCOUNT(state, data) {
      state.currentAccount = data;
    },
    SET_ACCESS_TOKEN(state, data) {
      state.accessToken = data;
    },
  },

  actions: {
    async handleLoginRedirect({ commit, dispatch, rootGetters }) {
      // Process the login redirect response
      const response = await msalInstance.handleRedirectPromise();

      let isAuthenticated = false;
      if (response !== null) {
        // Determine if the user is authorized based on groups or roles
        if (response.idTokenClaims.groups) {
          isAuthenticated = response.idTokenClaims.groups.some(
            (group) =>
              group === "GCAS-Admin" ||
              group === "SG-MKFS00- In-House CT Segmentation"
          );
        } else if (response.idTokenClaims.roles) {
          isAuthenticated = response.idTokenClaims.roles.some(
            (role) => role === "User"
          );
        }
        if (isAuthenticated) {
          // Set the active account for MSAL so that token acquisition works correctly
          msalInstance.setActiveAccount(response.account);
          commit("SET_IS_AUTHENTICATED", true);
          commit("SET_ACCESS_TOKEN", response.accessToken);
          commit("SET_ACCOUNT", response.account);
        } else {
          commit("SET_IS_AUTHENTICATED", false);
        }
      } else {
        try {
          // If no response, trigger login redirect
          await msalInstance.loginRedirect({});
          return; // Execution will stop here due to redirect
        } catch (err) {
          console.log("Login Error", err);
          return;
        }
      }

      // Now that authentication is complete, fetch protected users data.
      // Dispatch the "users/setUsers" action to trigger the API call.
      await dispatch("users/setUsers", null, { root: true });

      // Use the fetched users to determine the current account.
      const usersList = rootGetters["users/getUsers"];
      const account = rootGetters["accounts/getAccount"];
      const currentUser = usersList.find(
        (user) => user.email === account.username && user.userType === "User"
      );
      commit("SET_CURRENT_ACCOUNT", currentUser);
      localStorage.setItem("currentAccount", JSON.stringify(currentUser));
    },

    login() {
      msalInstance.loginRedirect({});
    },

    async logout({ state }) {
      const currentAccount = msalInstance.getAccountByHomeId(
        state.account.homeAccountId
      );
      await msalInstance.logoutRedirect({ account: currentAccount });
      console.log("Logged out:", currentAccount);
    },

    getAccount({ commit }) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts && accounts.length > 0) {
        commit("SET_ACCOUNT", accounts[0]);
        // Also set the active account so that token acquisition can use it.
        msalInstance.setActiveAccount(accounts[0]);
      } else {
        commit("SET_ACCOUNT", null);
      }
    },

    getToken({ state, commit }) {
      const currentAccount = msalInstance.getAccountByHomeId(
        state.account.homeAccountId
      );
      const silentRequest = {
        scopes: ["User.Read"],
        account: currentAccount,
        forceRefresh: false,
      };

      const request = {
        scopes: ["User.Read"],
        loginHint: currentAccount.username,
      };

      msalInstance.acquireTokenSilent(silentRequest).then(
        (res) => {
          commit("SET_ACCESS_TOKEN", res.accessToken);
          console.log("Token acquired silently", res);
        },
        (error) => {
          console.log("Silent token acquisition error:", error);
          return msalInstance.acquireTokenRedirect(request).then(
            (res) => {
              commit("SET_ACCESS_TOKEN", res.accessToken);
            },
            (err) => {
              console.log("Redirect token acquisition error:", err);
            }
          );
        }
      );
    },
  },

  getters: {
    getIsAuthenticated(state) {
      return state.isAuthenticated;
    },
    getAccount(state) {
      return state.account;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getCurrentAccount(state) {
      return state.currentAccount;
    },
  },
};

export default accounts;
