import { callApi } from "../../services/apiService";

const users = {
  namespaced: true,
  state: {
    user: {},
    users: [],
    loading: true,
  },

  mutations: {
    SET_USER(state, data) {
      state.user = data;
    },
    SET_USERS(state, data) {
      state.users = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },

  actions: {
    async setUser({ rootState, commit, dispatch }, payload) {
      try {
        const res = await callApi(
          "post",
          `${rootState.apiURL}/createUser`,
          payload
        );
        const user = res.data;
        if (user) {
          commit("SET_USER", user);
          dispatch("setUsers");
          console.log("Create user response: ", user);
        }
      } catch (error) {
        console.error("Error creating user:", error);
      }
    },

    async setUsers({ rootState, commit }) {
      commit("SET_LOADING", true);
      try {
        const res = await callApi("get", `${rootState.apiURL}/getUsers`);
        const usersData = res.data;
        if (usersData) {
          commit("SET_USERS", usersData);
        }
      } catch (error) {
        console.error("Failed to fetch users:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async deleteUser({ rootState, dispatch }, payload) {
      try {
        await callApi("post", `${rootState.apiURL}/deleteUser`, payload);
        dispatch("setUsers");
        console.log("Delete user response: ", payload);
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
  },

  getters: {
    getUser(state) {
      return state.user;
    },
    getUsers(state) {
      return state.users;
    },
    getLoading(state) {
      return state.loading;
    },
  },
};

export default users;
