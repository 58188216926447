<template>
  <div class="dashboard">
    <v-row>
      <v-col sm="12">
        <span class="headline">{{ pageName }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="4">
        <CaseChart />
      </v-col>
      <v-col sm="4">
        <RegionChart />
      </v-col>
      <v-col sm="4">
        <SourceChart />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <span class="h5">ACC Audit List</span>
      </v-col>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="repsuite"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.primaryContact`]="{ item }">
            {{ item.primaryContact.lastName }},
            {{ item.primaryContact.firstName }}
          </template>
          <template v-slot:[`item.inhouseSharingDateTime`]="{ item }">
            {{ item.inhouseSharingDateTime | formatDate }}
          </template>
          <template v-slot:[`item.needByDate`]="{ item }">
            {{ item.needByDate | formatDate }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex justify-space-between">
              {{ item.metadata.commentedRequestState.requestState }}
              <v-icon color="amber">
                mdi-alert
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CaseChart from "../components/charts/caseChart.vue";
import RegionChart from "../components/charts/regionChart.vue";
import SourceChart from "../components/charts/sourceChart.vue";
import { callApi } from "../services/apiService";

export default {
  components: { CaseChart, RegionChart, SourceChart },
  data: () => ({
    pageName: "Dashboard",
    acc: null,
    repsuite: [],
    loading: true,
  }),
  computed: {
    ...mapGetters("accounts", { accessToken: "getAccessToken" }),
    headers() {
      return [
        {
          text: "TPR ID",
          value: "externalReference",
        },
        {
          text: "Branch Name",
          value: "region",
        },
        {
          text: "MPS Name",
          value: "primaryContact",
          sortable: false,
        },
        {
          text: "Share Date",
          value: "inhouseSharingDateTime",
        },
        {
          text: "Need by Date",
          value: "needByDate",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
      ];
    },
    today() {
      return this.$moment().format("L");
    },
    accStatus() {
      return this.acc && this.acc.properties.state === "Enabled";
    },
  },
  methods: {
    async getRepsuite() {
      this.loading = true;
      try {
        const res = await callApi(
          "get",
          `${this.$store.state.apiURL}/getTreatmentPlanRequests`
        );
        this.repsuite = res.data.difference;
      } catch (error) {
        console.error("Error fetching treatment plan requests:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.$store.dispatch("cases/getCases");
    await this.getRepsuite();
  },
};
</script>

<style></style>
