import { callApi } from "../../services/apiService";

const holidays = {
  namespaced: true,
  state: {
    loading: true,
    holidays: [],
  },
  mutations: {
    SET_HOLIDAYS(state, data) {
      state.holidays = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    async getHolidays({ rootState, commit }) {
      commit("SET_LOADING", true);
      try {
        const res = await callApi("get", `${rootState.apiURL}/getHolidays`);
        const holidaysData = res.data;
        if (holidaysData) {
          commit("SET_HOLIDAYS", holidaysData);
        }
      } catch (error) {
        console.error("Error fetching holidays:", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
  getters: {
    getHolidays(state) {
      return state.holidays;
    },
    getLoading(state) {
      return state.loading;
    },
  },
};

export default holidays;
