import { callApi } from "../../services/apiService";

const settings = {
  namespaced: true,
  state: {
    timeValues: false,
    delayValue: false,
    daysToRejectValues: false,
    daysToRejectValue: false,
  },
  mutations: {
    SET_TIME_VALUES(state, data) {
      state.timeValues = data;
    },
    SET_DELAY_VALUE(state, data) {
      state.delayValue = data;
    },
    SET_DAYS_TO_REJECT_VALUES(state, data) {
      state.daysToRejectValues = data;
    },
    SET_DAYS_TO_REJECT_VALUE(state, data) {
      state.daysToRejectValue = data;
    },
  },
  actions: {
    async getSettings({ rootState, commit }) {
      try {
        const res = await callApi("get", `${rootState.apiURL}/getSettings`);
        const settingsItem = res.data.find(
          (item) => item.id === "TPR Delay Timer"
        );
        const daysToReject = res.data.find(
          (item) => item.id === "Days To Reject"
        );
        if (settingsItem && daysToReject) {
          commit("SET_TIME_VALUES", settingsItem.timeValues);
          commit("SET_DELAY_VALUE", settingsItem.delayValue);
          commit("SET_DAYS_TO_REJECT_VALUES", daysToReject.daysToRejectValues);
          commit("SET_DAYS_TO_REJECT_VALUE", daysToReject.daysToRejectValue);
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    },
    async updateSettings({ rootState, commit }, payload) {
      try {
        const res = await callApi(
          "post",
          `${rootState.apiURL}/updateSettings`,
          payload
        );
        const data = res.data;
        if (data.delayValue) {
          commit("SET_DELAY_VALUE", data.delayValue);
        }
        if (data.daysToRejectValue) {
          commit("SET_DAYS_TO_REJECT_VALUE", data.daysToRejectValue);
        }
      } catch (error) {
        console.error("Error updating settings:", error);
      }
    },
  },
  getters: {
    getTimeValues(state) {
      return state.timeValues;
    },
    getDelayValue(state) {
      return state.delayValue;
    },
    getDaysToRejectValues(state) {
      return state.daysToRejectValues;
    },
    getDaysToRejectValue(state) {
      return state.daysToRejectValue;
    },
  },
};

export default settings;
